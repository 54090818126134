import { useState } from 'react';
import Home from './screens/Home';
import Offer from './screens/Offer';
import { Route, Routes } from 'react-router-dom';
import Vision from './screens/Vision';
import Mission from './screens/Mission';
import Strategy from './screens/Strategy';
import Contact from './screens/Contact';
import Coaching from './screens/Coaching';

function App() {

  return (
    <Routes>
      <Route index element={<Home />}></Route>
      <Route path="coaching" element={<Coaching />}></Route>
      <Route path="ons-aanbod" element={<Offer />}></Route>
      <Route path="onze-visie" element={<Vision />}></Route>
      <Route path="onze-missie" element={<Mission />}></Route>
      <Route path="onze-strategie" element={<Strategy />}></Route>
      <Route path="contact-gegevens" element={<Contact />}></Route>
    </Routes>
  );
}

export default App;
