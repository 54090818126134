import Navbar from "../components/Navbar"
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai';

const Strategy = () => {
    return (
        <>
            <Navbar />
            <header id="strategy-banner">
                <p>Onze <br /><span>Strategie</span></p>
            </header>

            <main className="content">
                <section>
                    <h2>Inspireren</h2>
                    <p>Door zelf het rolmodel in het geheel
                        te zijn willen wij de mensen om
                        ons heen inspireren en motiveren
                        om het beste van zichzelf naar
                        boven te halen.
                        <br /><br />
                        Op deze manier willen wij onze
                        missie en visie realiseren.
                        <br /><br />
                        Wij willen mensen inspireren op
                        gebied van het ontdekken van
                        jouw eigen talent, hiervoor
                        bewustwording creëren en te
                        helpen om van jouw talent een
                        groot succes te maken.
                        <br /><br />
                        Zodra jij ooit geholpen bent bij
                        het behalen van jouw successen,
                        zie je hier de kracht van in en zal
                        je een ander ook sneller willen helpen
                        bij het behalen van zijn/haar succes.
                        <br /><br />
                        Wil jij hier onderdeel van zijn?
                        <br />
                        Neem dan contact met ons op.</p>
                </section>
                <Link to="/contact-gegevens" id="footer-button">Contact Opnemen <AiOutlineArrowRight /></Link>
            </main>
        </>
    )
}

export default Strategy