import Navbar from "../components/Navbar"
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai';

const Mission = () => {
    return (
        <>
            <Navbar />
            <header id="mission-banner">
                <p>Onze <br /><span>Missie</span></p>
            </header>

            <main className="content">
                <section>
                    <h2>Talentontwikkeling</h2>
                    <p>Wij ontdekken samen jouw potentie
                        om het vervolgens te maximaliseren.
                        <br /><br />
                        Dit kan op ieder gebied, voor ons
                        bestaan er geen problemen of grenzen.
                        <br /><br />
                        Het enige wat voor ons bestaat zijn
                        uitdagingen en dat is dan
                        precies net hetgeen dat wij het liefst
                        aangaan.
                        <br /><br />
                        Je staat er dus niet alleen voor. Wij
                        gaan samen met jou het gesprek
                        aan om te kijken waar jouw
                        huidige uitdagingen liggen en zullen
                        hier dan een gepaste strategie
                        voor uitwerken.
                        <br /><br />
                        Met als doel om jouw visie te bereiken
                        net als hoe wij onze visie willen
                        en gaan bereiken.
                        <br /><br />
                        Klinkt dit als muziek in de oren?
                        <br />
                        Neem dan contact met ons op.</p>
                </section>
                <Link to="/contact-gegevens" id="footer-button">Contact Opnemen <AiOutlineArrowRight /></Link>
            </main>
        </>
    )
}

export default Mission