import Navbar from "../components/Navbar"
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai';

const Contact = () => {
    return (
        <>
            <Navbar />
            <header id="contact-banner">
                <p>Contact <br /><span>Gegevens</span></p>
            </header>

            <main id="contact">
                <section>
                    <h2>E-mail</h2>
                    <a href="mailto:alex@eenjes.com">alex@eenjes.com</a>
                </section>
                <section>
                    <h2>Telefoon-nummer</h2>
                    <a href="tel:+31628070027">+31 6 28070027</a>
                </section>
            </main>
        </>
    )
}

export default Contact