import Navbar from "../components/Navbar"
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai';

const Vision = () => {
    return (
        <>
            <Navbar />
            <header id="vision-banner">
                <p>Onze <br /><span>Visie</span></p>
            </header>

            <main className="content">
                <section>
                    <h2>Autonimiteit</h2>
                    <p>Wij streven naar een autonome
                        samenleving, waarbij een ieder
                        opzoek gaat naar elkaars talent,
                        deze omarmt en hem/haar
                        helpt bij het behalen van zijn/haar
                        succes.
                        <br /><br />
                        Met als doel om een verbonden
                        samenleving te creëeren en ervoor
                        te zorgen dat zoveel mogelijk
                        individuen mentaal, fysiek
                        en financieel sterk in hun
                        schoenen komen te staan.
                        <br /><br />
                        Dit kunnen wij niet alleen, dit moeten
                        wij samen doen.
                        <br /><br />
                        Elkaar de arm om de
                        schouder slaan,  elkaar helpen,
                        elkaar lief hebben en
                        elkaar respecteren.
                        <br /><br />
                        Wil jij hier onderdeel van zijn?
                        <br />
                        Neem dan contact met ons op.</p>
                </section>
                <Link to="/contact-gegevens" id="footer-button">Contact Opnemen <AiOutlineArrowRight /></Link>
            </main>
        </>
    )
}

export default Vision