import Navbar from "../components/Navbar"
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai';

const Coaching = () => {
    return (
        <>
            <Navbar />
            <header id="coaching-banner">
                <p><span>Coaching</span></p>
            </header>

            <main className="coaching container">
                <section>
                    <img src="https://plus.unsplash.com/premium_photo-1661409082728-525f262714d4?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    <h2>Cursus</h2>
                    <p>Verdien geld met hetgeen
                        <br />
                        wat je hartstikke leuk
                        <br />
                        vindt om te doen.
                    </p>
                    <Link to="https://buy.stripe.com/8wMeXHa4M8qN0WAcNd" id="footer-button">Meer informatie <AiOutlineArrowRight /></Link>
                </section>
                <section>
                    <img src="https://plus.unsplash.com/premium_photo-1661670852605-449ab5687d9b?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    <h2>1 op 1 Traject</h2>
                    <p>Verdien geld met hetgeen
                        <br />
                        wat je hartstikke leuk
                        <br />
                        vindt om te doen.
                    </p>
                    <Link to="https://buy.stripe.com/7sIg1L90IgXjcFi7sV" id="footer-button">Meer informatie <AiOutlineArrowRight /></Link>
                </section>
                <section>
                    <img src="https://images.unsplash.com/photo-1461784180009-21121b2f204c?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    <h2>DJ Coaching</h2>
                    <p>Krijg persoonlijke begeleiding
                        <br />
                        om jouw online en offline
                        <br />
                        skills te maximaliseren.
                    </p>
                    <Link to="https://buy.stripe.com/dR6aHr6SA9uR20E5kM" id="footer-button">Meer informatie <AiOutlineArrowRight /></Link>
                </section>
            </main>
        </>
    )
}

export default Coaching