import Navbar from "../components/Navbar"
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai';

const Offer = () => {
    return (
        <>
            <Navbar />
            <header id="offer-banner">
                <p>Ons <br /><span>Aanbod</span></p>
            </header>

            <main className="container">
                <section>
                    <h2>Consultancy</h2>
                    <p>Door mee te denken met jouw
                        <br />
                        uitdagingen kunnen we samen
                        <br />
                        tot een oplossing komen.</p>
                </section>
                <section>
                    <h2>Coaching</h2>
                    <p>Waar nodig kunnen wij jou op
                        <br />
                        ieder gebied kennis en kunde
                        <br />
                        bieden door onze expertises.</p>
                </section>
                <section>
                    <h2>Begeleiding</h2>
                    <p>Los van al het meedenken en
                        <br />
                        kennis/kunde bieden kunnen
                        <br />
                        wij hierbij ook begeleiden.</p>
                </section>
                <section>
                    <h2>Marketing</h2>
                    <p>Wij helpen jou met het in
                        <br />
                        kaart brengen en bereiken
                        <br />
                        van jouw doelgroep.</p>
                </section>
                <section>
                    <h2>Content Creatie</h2>
                    <p>Ook kunnen wij de <br />content creëren dat past <br />bij jouw doelgroep.</p>
                </section>
                <section>
                    <h2>Ontwerp</h2>
                    <p>Niet alleen kunnen wij content<br /> creëeren, ook maken wij <br />designs op maat.</p>
                </section>
                <section>
                    <h2>Development</h2>
                    <p>Een website of applicatie nodig?<br /> Ook deze vaardigheden en kennis<br /> hebben wij in huis.</p>
                </section>
                <section>
                    <h2>Appointmentsetting</h2>
                    <p>Onze ervaren appointmentsetters<br /> leiden jouw potentiële klant <br />naar de sales agents.</p>
                </section>
                <section>
                    <h2>Sales</h2>
                    <p>Iedere lead die voortkomt <br />uit de marketing en/of<br /> content kunnen wij closen.</p>
                </section>
                <section>
                    <h2>Muziek</h2>
                    <p>Artiesten en beats zijn <br />voor ons ook geen <br />uitdaging.</p>
                </section>
                <section>
                    <h2>Influencers</h2>
                    <p>Een bekend gezicht <br />inzetten voor jouw bedrijf<br /> of merk? Geen probleem!</p>
                </section>
                <section>
                    <h2>Klantenservice</h2>
                    <p>Jouw klanten tevreden <br />stellen en houden is voor <br />ons geen uitdaging.</p>
                </section>
                <Link to="/contact-gegevens" id="footer-button">Contact Opnemen <AiOutlineArrowRight /></Link>
            </main>
        </>
    )
}

export default Offer