import { useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowRight } from 'react-icons/ai';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom'

const Home = () => {
    const [toggle, setToggle] = useState(false)

    const handleLinkClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div id="home-header">
                <Navbar />
                <header id="home-banner">
                    <p>Maximaliseer<br /><span>jouw</span>potentie</p>
                    <div>
                        <AiOutlineArrowDown />
                    </div>
                </header>
            </div>
            <main>
                <h2>Voor</h2>
                <div id="home-buttons">
                    <button className={toggle ? '' : 'active'} onClick={() => setToggle(false)}>Particulieren</button>
                    <button className={toggle ? 'active' : ''} onClick={() => setToggle(true)}>Bedrijven</button>
                </div>
                {toggle ?
                    <div id="bedrijven">
                        <section>
                            <h2>Maximize Agency</h2>
                            <p>We ontdekken samen jouw
                                <br />
                                potentie om het vervolgens te
                                <br />
                                maximaliseren.</p>
                        </section>
                        <section>
                            <h2>Waarom</h2>
                            <p>Wij dragen graag bij aan de
                                <br />
                                groei van jouw bedrijf om haar
                                <br />
                                potentie maximaal te benutten.</p>
                        </section>
                        <section>
                            <h2>Hoe</h2>
                            <p>Dit doen wij door het gesprek
                                <br />
                                met jou aan te gaan en het
                                <br />
                                juiste aanbod te leveren.</p>
                        </section>
                        <section>
                            <h2>Wat</h2>
                            <p>Wij ondersteunen van consultancy,
                                <br />
                                marketing, sales, levering
                                <br />
                                en/of zelfs tot klantenservice.</p>
                        </section>
                    </div>
                    :
                    <div id="particulieren">
                        <section>
                            <h2>Maximize Agency</h2>
                            <p>We ontdekken samen jouw
                                <br />talent om het te ontwikkelen
                                en <br /> tot een succes te maken.</p>
                        </section>
                        <section>
                            <h2>Waarom</h2>
                            <p>Wij willen bijdragen aan jouw
                                <br />
                                talentontwikkeling, zowel <br />
                                op persoonlijk als zakelijk gebied.</p>
                        </section>
                        <section>
                            <h2>Hoe</h2>
                            <p>Dit doen wij door het gesprek
                                <br />
                                met jou aan te gaan en jouw
                                <br />
                                begeleiding op maat aan te bieden.</p>
                        </section>
                        <section>
                            <h2>Wat</h2>
                            <p>Wij begeleiden talenten van
                                <br />
                                van A tot Z door middel van onze
                                <br />
                                kennis, expertises en netwerk.</p>
                        </section>
                    </div>
                }
                <Link to="/ons-aanbod" id="footer-button" onClick={handleLinkClick}> Meer over ons aanbod <AiOutlineArrowRight /></Link>
            </main>
        </>
    )
}

export default Home